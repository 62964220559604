function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Confirm as confirm, Alert as alert, Toast as toast, Notify as notify, Loading as loading } from "vue-ydui/dist/lib.rem/dialog";
var dialog = {
  confirm: confirm,
  alert: alert,
  toast: toast,
  notify: notify,
  loading: loading
};
var icons = {
  error: "操作失败",
  success: "操作成功"
};
Object.keys(icons).reduce(function (dialog, key) {
  dialog[key] = function (mes) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new Promise(function (resolve) {
      toast(_objectSpread({
        mes: mes || icons[key],
        timeout: 1000,
        icon: key,
        callback: function callback() {
          resolve();
        }
      }, obj));
    });
  };
  return dialog;
}, dialog);
dialog.message = function () {
  var mes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "操作失败";
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve) {
    toast(_objectSpread({
      mes: mes,
      timeout: 1000,
      callback: function callback() {
        resolve();
      }
    }, obj));
  });
};
dialog.validateError = function () {
  validatorDefaultCatch.apply(void 0, arguments);
};
export function validatorDefaultCatch(err) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "message";
  console.log(err);
  return dialog[type](err.errors[0].message);
}
export default dialog;